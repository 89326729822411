<template>
  <div class="home-container">
    <div data-role="Header" class="home-navbar-container">
      <div class="home-navbar">

        <div class="home-left-side">
          <img
            alt="image"
            src="/playground_assets/krispro%20agregaty-200h.jpg"
            class="home-image"
          />
        </div>
        <div class="home-right-side"></div>
        <div data-role="MobileMenu" class="home-mobile-menu">
          <div class="home-container1">
            <img
              alt="image"
              src="https://play.teleporthq.io/static/svg/default-img.svg"
              class="home-image1"
            />
            <div data-role="CloseMobileMenu" class="home-close-menu">
              <svg viewBox="0 0 1024 1024" class="home-icon02">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="home-links-container">

          </div>
        </div>
      </div>
    </div>
    <div id="resources" class="home-hero"></div>
    <div id="inspiration" class="home-features">
      <div class="home-heading-container"></div>
    </div>
    <div class="home-get-in-touch">
      <h2 class="home-text Section-Heading">Kontakt</h2>
      <div class="home-content-container">
        <div class="home-locations-container">
          <div class="home-location-1">
            <span class="home-heading">Krzysztof Garncarek</span>
            <div class="home-adress">
              <svg viewBox="0 0 1024 1024" class="home-icon04">
                <path
                  d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"
                ></path>
              </svg>
              <span class="home-text1">
                <span class="Section-Text">ul. Sulisławicka 60</span>
                <br />
                <span class="Section-Text">62-800 Kalisz</span>
              </span>
            </div>
            <div class="home-email">
              <svg viewBox="0 0 1024 1024" class="home-icon06">
                <path
                  d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
                ></path>
              </svg>
              <span class="home-text5">biuro@krispro.pl</span>
            </div>
            <div class="home-phone">
              <svg viewBox="0 0 804.5714285714286 1024" class="home-icon08">
                <path
                  d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"
                ></path>
              </svg>
              <span class="home-text6">+48 504 803 743</span>
            </div>
            <span class="home-text7">NIP: 618-206-13-40</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-section-separator"></div>
    <div class="home-footer-container">
      <div class="home-footer">
        <div class="home-copyright-container">
          <svg viewBox="0 0 1024 1024" class="home-icon14">
            <path
              d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"
            ></path>
          </svg>
          <span class="Anchor">Copyright, 2023</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Landing Page',
    meta: [
      {
        property: 'og:title',
        content: 'Landing Page',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #04161F;
}
.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #b9b9b9;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-left-side {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image {
  width: 208px;
  height: 71px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-right-side {

}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100VH;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon02 {
  width: 24px;
  height: 24px;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link3 {
  text-decoration: none;
}
.home-hero {
  width: 100%;
  height: 617px;
  display: flex;
  align-items: center;
  padding-top: 313px;
  margin-bottom: 30px;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/strona%20foto%20grafika%20logo-1500w.jpg");
}
.home-features {
  width: 100%;
  height: 816px;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: 305px;
  padding-right: 6px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-size: cover;
  background-image: url("/playground_assets/strona%20foty%20%20kolaz%20grafika%20logo-900h.jpg");
}
.home-heading-container {
  width: 45%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-get-in-touch {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-text {
  color: #f7f2f2;
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-content-container {
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-locations-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-location-1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-heading {
  color: #f7f5f5;
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-adress {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon04 {
  width: 24px;
  height: 24px;
  border-color: #ffffff;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text1 {
  color: #ffffff;
}
.home-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text5 {
  color: #fdfbfb;
}
.home-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon08 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text6 {
  color: #fbf9f9;
}
.home-text7 {
  color: #fbf8f8;
  text-align: center;
  padding-left: 38px;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon12 {
  width: 24px;
  height: 24px;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon14 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 991px) {
  .home-navbar {
    height: 78px;
  }
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-locations-container {
    flex-wrap: wrap;
  }
  .home-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-gray-black);
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-hero {
    padding-right: var(--dl-space-space-tenunits);
  }
  .home-heading-container {
    width: 100%;
  }
  .home-content-container {
    width: 100%;
    flex-direction: column;
  }
  .home-locations-container {
    flex-direction: row;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .home-hero {
    width: 100%;
    height: 188px;
    padding-top: var(--dl-space-space-fourunits);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-content-container {
    padding-left: 0px;
  }
  .home-location-1 {
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
</style>
